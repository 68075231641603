<template>
  <div>
    <div class="stepper-line">
      <div class="stepper-progress" :style="{width: setWidth}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppProgress',
  props: {
    stepperPosition: {
      type: Number,
      default: 0
    },
    maxSteps: {
      type: Number,
      default: 6
    }
  },
  computed: {
    setWidth: function () {
      const width = (100 * this.stepperPosition) / this.maxSteps
      return `${width <= 100 ? width : 100}%`
    }
  }
}
</script>

<style scoped lang="scss">
  .stepper- {
    &line {
      width: 100%;
      background-color: white;
      height: 10px;
      border: 1px solid #dddddd;
      border-radius: 3px;
      margin-top: 15px;
      margin-bottom: 30px;
    }
    &progress {
      width: 0;
      height: 100%;
      background-color: #00b060;
      transition-duration: 0.28s;
      transition-property: width;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
</style>
