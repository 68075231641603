<template>
  <div class="tree-content">
    <div class="subtitle">Estimate the complexity and cost selection of a specialist right now:</div>
    <div class="tree-list">
      <div v-for="item in getFullTree" :key="item.id" class="tree-item">
        <div ref="select" v-if="selectIsVisible(item)" >
          <v-autocomplete :label="item.title_en"
                          :items="getItems[item.id]"
                          :value="checkItemInSelected(getItems[item.id])"
                          item-text="title_en"
                          item-value="(item)=>item"
                          return-object
                          solo
                          dense
                          flat
                          outlined
                          hide-details
                          color="#00b060"
                          @change="addItem($event, item)"
                        />
          <div class="input-item" v-show="textAreaIsVisible(item)">
          <v-text-field
                  :value="textAreaValue (item)"
                  placeholder="Missing category of interest? Just type it!"
                  solo
                  outlined
                  flat
                  dense
                  hide-details
                  color="#00b060"
                  @change="addItemValue($event, item)"/>
           </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

function clearEmptyItems (arr, result = null) {
  if (result || arr.length === 0) return arr
  let newResult = true
  const newArr = arr.filter((el) => {
    if (el.item_parent_id === 'NULL') return true
    const res = arr.some((otherItem) => el.item_parent_id === otherItem.id)
    if (!res) newResult = false
    return res
  })
  return clearEmptyItems(newArr, newResult)
}

export default {
  name: 'AppProfessionsSelector',
  props: {
    selectedItems: Array
    // treeItems: Array,
    // items: Object
  },
  data () {
    return {
      showNextStep: false
    }
  },
  computed: {
    ...mapGetters(['getSelectedItems', 'getItems', 'getFullTree'])
  },
  watch: {
    selectedItems: function (val) {
      Vue.nextTick(() => {
        const el = this.selectedItems[this.$refs?.select?.length - 1]
        this.showNextStep = (this.$refs?.select?.length === val.length) || (el && el.title === 'Other' && el.value.length > 3)
      })
    },
    showNextStep: function (val) {
      if (val) {
        const items = [
          ...this.selectedItems
        ]
        this.$emit('change', items)
      } else {
        this.$emit('change', null)
      }
    }
  },
  methods: {
    ...mapActions(['changeSelectedItems']),
    checkItemInSelected (items) {
      return items.find(
        item => {
          var itemInSelected = this.selectedItems.find(
            selected => selected.id === item.id
          )
          return itemInSelected
        }
      )
    },
    addItem (elm, parent) {
      if (elm) {
        let selectedItems = this.selectedItems.filter(el => el.parent_id !== parent.id)
        selectedItems = clearEmptyItems(selectedItems, null)
        selectedItems.push({ ...elm, item_parent_id: parent.parent_id })
        this.$emit('changeItems', selectedItems)
      }
    },
    addItemValue (what, item) {
      let el = this.selectedItems[this.selectedItems.length - 1]
      el = {
        ...el,
        value: what
      }
      this.selectedItems[this.selectedItems.length - 1] = el
      this.$emit('changeItems', this.selectedItems)
    },
    selectIsVisible (item) {
      return this.selectedItems.find(el => el.id === item.parent_id) || item.parent_id === 'NULL'
    },
    textAreaIsVisible (item) {
      return this.selectedItems.find(el => (el.title === 'Other' || el.title_en === 'Other') && el.parent_id === item.id)
    },
    textAreaValue (item) {
      var otherElement = this.selectedItems.find(el => el.parent_id === item.id && (el.title === 'Other' || el.title_en === 'Other'))
      var textValue = ''
      if (otherElement) {
        textValue = otherElement.value
      }
      return textValue
    }
  }
}
</script>

<style scoped lang="scss">
  #calc-app .tree-content {
    & .tree-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .v-input {
        margin-bottom: 15px;
      }
    }

    & .v-list-item__content {
      text-align: left;
    }

    & .v-select__slot input {
      cursor: pointer
    }

    & .tree-item, .input-item {
      width: 100%;
    }
  }
</style>
