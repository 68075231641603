<template>
  <div>
    <div class="subtitle">Please, share job opening stats for more accurate recommendations:</div>
    <v-checkbox
      v-model="switchStatistic"
      color="#00b060"
      @change="$emit('hasStatistic', !$event)"
      label="Skip this step (I don’t have stats щк I'm not willing to share any stats)"
    ></v-checkbox>
    <v-container v-if="!switchStatistic" class="statistic__container">
      <v-col>
        <v-row>
          <div class="subtitle">How many candidates have you interviewed (approximately)?</div>
          <v-spacer></v-spacer>
          <v-text-field solo outlined type="number" color="#00b060" v-model="statistic.candidatesCount"/>
        </v-row>
        <v-row>
          <div class="subtitle">How many interview rounds does each candidate go through?</div>
          <v-spacer></v-spacer>
          <v-select :items="[1, 2, 3, '>3']" solo outlined color="#00b060" v-model="statistic.interviewRounds"/>
        </v-row>
        <v-row>
          <div class="subtitle">Do you evaluate the candidate’s technical skills with coding tasks?</div>
          <v-spacer></v-spacer>
          <v-select :items="evaluateVariants" solo outlined color="#00b060" v-model="statistic.evaluate"/>
        </v-row>
        <v-row>
          <div class="subtitle">Please, specify the total number of job offers made on the position</div>
          <v-spacer></v-spacer>
          <v-text-field solo outlined color="#00b060" v-model="statistic.jobOffer"/>
        </v-row>
        <v-row>
          <div class="subtitle">Please, specify the total number of candidates hired for the position</div>
          <v-spacer></v-spacer>
          <v-text-field solo outlined color="#00b060" v-model="statistic.candidatesHired"/>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'AppVacancyStatistic',
  data () {
    return {
      switchStatistic: false,
      statistic: {
        candidatesCount: null,
        interviewRounds: 1,
        evaluate: 'Yes, in the course of interview',
        jobOffer: 0,
        candidatesHired: 0
      },
      evaluateVariants: [
        'No',
        'Yes, before the interview',
        'Yes, in the course of interview',
        'Yes, after the interview',
        'Other'
      ]
    }
  },
  computed: {
    showNextBtn: function () {
      return this.switchStatistic || (this.statistic.candidatesCount !== null)
    }
  },
  watch: {
    statistic: {
      handler: function (val) {
        this.$emit('change', val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .v-input--checkbox {
    margin-bottom: 0px;
    & .v-messages {
      display: none;
    }
  }

  .statistic__container {
    padding-left: 0;
    padding-right: 0;
    .v-input {
      max-width: 170px;

      @media screen and (max-width: 440px) {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 440px) {
      .spacer {
        display: none;
      }
    }
  }
</style>
