<template>
  <div class="nav-container">
    <v-dialog
      v-model="showAlert"
      persistent
      min-width="320"
      max-width="440"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="btn-container">
          <v-btn v-if="currentStep === 'result'"
                 class="btn-item gray"
                 v-bind="attrs"
                 v-on="on">
            Reset
          </v-btn>
          <v-btn v-else-if="currentStep && currentStep !== 'result'"
                 class="btn-item"
                 @click="prevStep">
            Back
          </v-btn>
          <v-btn v-if="currentStep !== 'result'"
                 :class="['btn-item', disabled ? 'disabled' : null]"
                 :disabled="disabled"
                 @click="nextStep">
            Next
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to reset calculation to the beginning?
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="clearAll"
          >
            Yes
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#00b060"
            text
            @click="showAlert = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AppNav',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    currentStep: String
  },
  data () {
    return {
      showAlert: false
    }
  },
  methods: {
    nextStep: function () {
      this.$emit('nextStep')
    },
    prevStep: function () {
      this.$emit('prevStep')
    },
    clearAll: function () {
      this.showAlert = false
      console.log('CkearAll complite')
      this.$emit('clearAll')
    }
  }
}
</script>

<style scoped>
  .v-card__title {
    word-break: break-word;
    margin-bottom: 2rem;
  }

  .v-card__actions {
    padding: 16px !important;
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
  }
</style>
