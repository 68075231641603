<template>
  <div>
    <div class="subtitle">What is your business model:</div>
    <div :class="['radio-wrapper', values.length % 2 === 0 ? 'radio-wrapper_even' : null]">
      <AppRadioButton v-for="i in values"
                      :key="i.id"
                      :label="i.label"
                      :description = "i.description"
                      :selected="selected"
                      :value="i.id"
                      @change="$emit('change', i.id)"/>
    </div>
  </div>
</template>

<script>
import AppRadioButton from './AppRadioButton.vue'

export default {
  name: 'AppProjectTypeRadio',
  components: {
    AppRadioButton
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    values: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>
