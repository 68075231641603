<template>
  <div  :class="{active: active, errormessage: isError }" class="message-body">
    <div class="message-wrapper">
      {{label}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppMessage',
  props: {
    active: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    label: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.message-body {
  position: fixed;
  top: -999px;
  right: 50px;
  background: #fffcf7;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px rgb(199 199 199);
  transition: 1s;
  color: green;
  &.active {
    top: 50px;
  }
  &.errormessage {
    color: red
  }
}
.message-wrapper {
  padding: 20px;
}
</style>