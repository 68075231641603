import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

const font = document.createElement('link')
font.rel = 'stylesheet'
font.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900'
document.body.appendChild(font)

const icon = document.createElement('link')
icon.rel = 'stylesheet'
icon.href = 'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css'
document.body.appendChild(icon)

const mountEl = document.getElementById('calcWidget')
if (!mountEl) {
  const el = document.createElement('div')
  el.id = 'calcWidget'
  document.body.appendChild(el)
}

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#calcWidget')
