<template>
  <div class="salary-range">
    <div class="subtitle">Choose annual rate (before tax):</div>
      <v-range-slider
        :max="max"
        :min="min"
        :step="step"
        color="#00b060"
        track-color="#00b060"
        thumb-label="always"
        :value="salary"
        @input="$emit('change', $event)"
      >
        <template v-slot:prepend>
          <v-text-field
            :value="salary[0]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 80px"
            :step="step"
            :min="min"
            :max="max"
            color="#00b060"
            prefix="$"
            @change="$set(salary, 0, $event)"
          ></v-text-field>
        </template>

        <template v-slot:append>
          <v-text-field
            :value="salary[1]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 80px"
            :step="step"
            :min="min"
            :max="max"
            color="#00b060"
            prefix="$"
            @change="$set(salary, 1, $event)"
          ></v-text-field>
        </template>
      </v-range-slider>
  </div>
</template>

<script>
export default {
  name: 'AppSalaryRange',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 25000
    },
    step: {
      type: Number,
      default: 100
    },
    salary: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
  #calc-app {
    & .v-text-field > .v-input__control > .v-input__slot:before {
      border: none !important;
    }
  }
  #calc-app .salary-range {
    & .v-slider__thumb-container {
      cursor: pointer;
      & .v-slider__thumb {
        border-radius: 0;
        height: 24px;
        width: 8px;
        transition: none;

        &:before, &:after {
          width: 0;
          height: 0;
          transform: scale(0);
        }
      }

      &:hover {
        & .v-slider__thumb {
          opacity: .9;

          &:before, &:after {
            width: 0;
            height: 0;
            transform: scale(0);
          }
        }
      }

      & .v-slider__thumb-label {
        transform: translateY(40px) translateX(-60%) rotate(45deg) !important;
        color: #000 !important;
        background: none !important;
      }
    }

    & .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after{
      background-color: #00b060;
      border-color: #00b060;
      border-style: solid;
      border-width: thin 0 thin 0;
      transform: scaleX(1);
    }

    & .v-text-field > .v-input__control > .v-input__slot:after {
      background-color: lightgray;
      border-color: lightgray;
      border-style: solid;
      border-width: thin 0 thin 0;
      transform: scaleX(1);
    }

    & .v-input__append-outer, .v-input__prepend-outer {
      min-width: 85px;
    }
    @media (max-width: 860px) {
      & .v-input {
        flex-wrap: wrap;
      }
      & .v-input__append-outer, .v-input__prepend-outer {
        min-width: 100px;
        margin: 0
      }
    }
  }
</style>
