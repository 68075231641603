<template>
  <div>
    <div class="subtitle">Do you evaluate the candidate’s technical skills with coding tasks?</div>
    <v-select :items="evaluateVariants"
              solo
              outlined
              color="#00b060"
              :value="evaluate"
              dense
              hide-details
              @change="$emit('change', $event)"/>
  </div>
</template>

<script>
export default {
  name: 'AppVacancyStatisticStep1',
  props: {
    evaluate: {
      type: String,
      default: 'Yes, in the course of interview'
    }
  },
  data () {
    return {
      evaluateVariants: [
        'No',
        'Yes, before the interview',
        'Yes, in the course of interview',
        'Yes, after the interview',
        'Other'
      ]
    }
  }
}
</script>
