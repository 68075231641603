<template>
  <div
    :class="[
      'radio',
      selected === value ? 'radio-active' : null,
      !!$slots.left ? 'radio-left' : null,
    ]"
    @click="$emit('change', value)"
  >
    <slot name="left"></slot>
    <div class="label__wrapper">
      <div class="radio__label" v-text="label" />
        <p  v-if="description" class="text__description" v-html="description"></p>
    </div>

    <div
      :class="[
        'radio__selector',
        selected === value ? 'radio__selector-active' : null,
      ]"
    >
      <svg
        v-show="selected === value"
        width="11"
        height="9"
        viewBox="0 0 11 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 1L3.7 8L1 5.49989"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppRadioButton',
  props: {
    label: [String, Number],
    value: [String, Number],
    description: [String, Number],
    selected: [String, Number]
  }
}
</script>

<style lang="scss" scoped>
.label__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text__description {
  font-size: 12px;
  font-weight: 400;
  align-self: start;
  margin: 0;
  padding: 5px;
  text-align: center;
  color: cadetblue;
}
.radio {
  &-active {
    border-color: #4fab68;
  }
  &-left {
    justify-content: space-between;
  }
  cursor: pointer;
  width: 307px;
  height: 100px;
  margin: 10px;
  padding: 0 37px 0 24px;
  border: 2px solid #cfd3d8;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 16px;
  font-feature-settings: "liga" off;
  color: #11253d;
  &__label {
    padding: 10px 0;
  }
  &__selector {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #cfd3d8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1px;
    &-active {
      border-color: #4fab68;
      background-color: #4fab68;
      color: #ffffff;
    }
  }

}
</style>
