<template>
  <div>
    <!-- <h1>Result</h1> -->
    <div class="statistics">
      <!-- <v-text-field
      label="Number of specialists on the market"
      value="Please, provide your e-mail"/>
      Number of specialists on the market
      Looking for a job now
      Demand for specialists -->
      <v-container>

          <v-row style="margin-bottom: 78px; align-items: center;">
            <v-col>
              <v-subheader>The difficulty of closing a vacancy</v-subheader>
            </v-col>
            <v-col cols = "6">
              <div class="complexity">
                <div
                  class="complexity_bar"
                  v-bind:style="{ left: complexity + '%' }"
                ></div>
                <div
                  class="complexity_label"
                  v-bind:style="{ left: complexity + '%' }"
                >
                  {{ difficulty }}
                </div>
              </div>
            </v-col>
            <v-col>
              <v-subheader>The self-closing period <br> is {{ daysToHire }} days</v-subheader>
            </v-col>
          </v-row>

          <v-row  style="height: 80px;">
          <v-col cols = "3" offset-md="0" align-self="center">
            <v-input readonly>Number of specialists on the market</v-input>
          </v-col>
          <v-col>
            <v-text-field
              color="#252525"
              class="not-margin"
              label="Please, provide your e-mail"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

         <v-row  style="height: 80px;">
          <v-col cols = "3" offset-md="0" align-self="center">
            <v-input readonly>Looking for a job now</v-input>
          </v-col>
          <v-col>
            <v-text-field
              color="#252525"
              class="not-margin"
              label="Please, provide your e-mail"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
         <v-row  style="height: 80px;" >
          <v-col cols = "3" offset-md="0" align-self="center">
            <v-input  readonly>Demand for specialists</v-input>
          </v-col>
          <v-col>
            <v-text-field
              color="#252525"
              class="not-margin"
              label="Please, provide your e-mail"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row  style="height: 80px;">
          <v-col align-self="end" style="text-align: left;">
            These stats are based on the information you provided and SymbioWay IT Recruiting Center's analytical
            department data. To get more data and more accurate data, please provide your e-mail. We also will provide you a free review of your vacancy:
          </v-col>
        </v-row>

         <!-- <v-row  justify="space-between" style="margin-top: 40px; padding: 12px;"> -->
          <v-form class="d-flex align-start justify-space-between w-100" style=" margin-top: 40px; padding: 12px 12px 12px 0px;" v-model="isValidateEmail">
            <v-text-field
            class="not-margin"
            label="Your e-mail"
            :rules="[rules.required, rules.email]"
            v-model="email"
            outlined/>
              <v-btn class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default btn-item" @click="sendOnEmail" :disabled="!email || !isValidateEmail"> Send me more data </v-btn>
          </v-form>
        <!-- </v-row> -->
      </v-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'AppResult',
  data () {
    return {
      complexity: 0,
      difficulty: '',
      daysToHire: 0,
      email: '',
      isValidateEmail: null,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  props: {
    result: {
      complexity: Number,
      daysToHire: Number,
      result: Boolean,
      error: String
    }
  },
  methods: {
    sendOnEmail () {
      this.$emit('sendOnEmail', this.email)
      this.email = ''
    }
  },
  watch: {
    result: function (val) {
      if (val) {
        Vue.nextTick(() => {
          this.complexity = this.result.complexity * 100

          if (this.result.complexity < 0.2) {
            this.difficulty = 'low'
          }
          if (this.result.complexity >= 0.2 && this.result.complexity < 0.4) {
            this.difficulty = 'below average'
          }
          if (this.result.complexity >= 0.4 && this.result.complexity < 0.6) {
            this.difficulty = 'average'
          }
          if (this.result.complexity >= 0.6 && this.result.complexity < 0.8) {
            this.difficulty = 'above average'
          }
          if (this.result.complexity >= 0.8 && this.result.complexity < 1) {
            this.difficulty = 'high'
          }

          this.daysToHire = this.result.daysToHire
        })
      }
    }
  }
}
</script>
<style lang="scss">

.complexity {
  min-height: 10px;
  height: 25px;
  max-height: 25px;
  background: linear-gradient(
    90deg,
    rgba(37, 214, 36, 1) 0%,
    rgba(242, 255, 86, 1) 50%,
    rgba(209, 76, 76, 1) 100%
  );
  border: 1px solid rgb(73, 71, 67);
  border-radius: 2px;
}
.complexity_bar {
  position: relative;
  top: -3px;
  width: 10px;
  height: 30px;
  border: 1px solid rgb(73, 71, 67);
  border-radius: 2px;
  background: rgb(185, 185, 184);
}
.complexity_label {
  position: relative;
  min-width: 10%;
  width: 20%;
  margin: 10px 0;
  font-size: 0.9em;
}
.complexity_day {
  font-size: 0.7em;
  margin: 10px;
}
</style>
